<template>
    <crud-index-component v-bind="$data">
        <template slot="header">{{ $t('crud.transporters._plural') }}</template>
    </crud-index-component>
</template>

<script>
    import CrudIndexComponent from "../crud/Index";
    import {config} from '../crud/mixin_config';

    export default {
        name: 'transporters-index-component',
        components: {CrudIndexComponent},
        data() {
            return {
                crud: config('transporters'),
                fields: [{
                        title: this.$t('crud.id'),
                        name: 'id',
                        sortField: 'id'
                    },
                    {
                        title: this.$t('crud.name'),
                        name: 'name',
                        sortField: 'name'
                    },
                    {
                        title: this.$t('crud.sorting_order'),
                        name: 'sorting_order',
                        sortField: 'sorting_order'
                    },
                    {
                        title: this.$t('crud.transporters.limit_cart_half_euro'),
                        name: 'limit_cart_half_euro',
                        sortField: 'limit_cart_half_euro'
                    },
                    {
                        title: this.$t('crud.transporters.limit_cart_euro'),
                        name: 'limit_cart_euro',
                        sortField: 'limit_cart_euro'
                    },
                    {
                        title: this.$t('crud.transporters.limit_cart_block'),
                        name: 'limit_cart_block',
                        sortField: 'limit_cart_block'
                    },
                    {
                        title: this.$t('crud.transporters.limit_transport_half_euro'),
                        name: 'limit_transport_half_euro',
                        sortField: 'limit_transport_half_euro'
                    },
                    {
                        title: this.$t('crud.transporters.limit_transport_euro'),
                        name: 'limit_transport_euro',
                        sortField: 'limit_transport_euro'
                    },
                    {
                        title: this.$t('crud.transporters.limit_transport_block'),
                        name: 'limit_transport_block',
                        sortField: 'limit_transport_block'
                    },
                    {
                        title: this.$t('crud.updated_at'),
                        name: 'updated_at',
                        sortField: 'updated_at'
                    },
                    {
                        title: this.$t('crud.actions'),
                        name: 'slot-actions'
                    }
                ],
            };
        },
    }
</script>
